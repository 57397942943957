import React, { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hexToRgb } from '../helpers';

const ButtonStyled = styled.button`
  height: 50px;
  width: 150px;
  padding: 10px;
  margin: 10px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  outline: transparent;
  color: ${p => p.theme.bgDark};
  background-color: ${p => p.theme.accentLight};
  border: 2px solid ${p => p.theme.accentLight};
  border-radius: 50px;
  box-shadow: 
    0 1px 2px 0 rgba(${p => hexToRgb(p.theme.bgDarker)}, 0.35),
    0 1px 3px 1px rgba(${p => hexToRgb(p.theme.bgDarker)}, 0.20);
  transition: all 0.25s;

  &:active {
    /* background-color: rgba(${hexToRgb(p => p.theme.accentLight)}, 0.2); */
    box-shadow: 0 0 4px 2px transparent;
  }
`;

const reducer = (currentState, newState) => {
  return { ...currentState, ...newState };
};

const Button = props => {
  const [state, setState] = useReducer(reducer, {
    lastEventType: null,
    lastEventTime: null,
    longpressTime: null,
  });
  const { longpressTime } = state;
  const longpressDelay = 750;

  // to use updated state in a callback
  const stateRef = useRef(state);
  stateRef.current = state;

  const checkLongpress = () => {
    const { lastEventType, lastEventTime } = stateRef.current;
    const now = new Date().getTime();

    if (lastEventType === 'touchstart' && now >= lastEventTime + longpressDelay) {
      return true;
    }
    return false;
  };

  const touchstartHandler = () => {
    setTimeout(() => {
      if (checkLongpress()) {
        props.longpress();
        setState({ longpressTime: new Date().getTime() });

        try {
          navigator.vibrate(25);
        } catch (error) {
          console.log(error);
        }
      }
    }, longpressDelay);
  };

  const eventsHandler = async e => {
    const now = new Date().getTime();
    if (e.type === 'touchstart') {
      touchstartHandler();
    } else if (e.type === 'mouseup' && now >= longpressTime + 500) {
      props.click();
    }
    setState({ lastEventType: e.type, lastEventTime: now });
  };

  return (
    <ButtonStyled
      onMouseDown={eventsHandler}
      onTouchStart={eventsHandler}
      onMouseUp={eventsHandler}
      onTouchEnd={eventsHandler}
    >
      {props.children}
    </ButtonStyled>
  );
};

Button.propTypes = {
  click: PropTypes.func.isRequired,
  longpress: PropTypes.func,
  children: PropTypes.string.isRequired,
};

Button.defaultProps = {
  longpress: () => {},
};

export default Button;
