import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';
import { formatTime } from '../helpers';
import '../animations.css';

const Set = styled.ul`
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  border-bottom: 2px solid ${props => props.theme.accentLight};
  border-top: none;
  font-size: 17px;

  @media (min-width: 768px) {
    width: 45%;
  }
`;

const Count = styled.li`
  color: ${props => props.theme.accentLight};
  padding: 8px;
  font-weight: bold;
`;

const Item = styled.li`
  padding: 8px;
  color: ${props => props.theme.accentLight};
`;

const set = props => (
  <CSSTransition {...props} classNames="slide" timeout={{ enter: 200, exit: 500 }}>
    <Set data-testid="set">
      <Count data-testid="count">#{props.details.count}</Count>
      <Item data-testid="exercise">{formatTime(props.details.EXERCISE)}</Item>
      {props.details.PAUSE && <Item data-testid="pause">{formatTime(props.details.PAUSE)}</Item>}
    </Set>
  </CSSTransition>
);

set.propTypes = {
  details: PropTypes.object.isRequired,
};

export default React.memo(set);
