import React from 'react';
import styled from 'styled-components';
import { hexToRgb } from '../helpers';

const StyledPopup = styled.div`
  color: ${p => p.theme.accentLight};
  background-color: ${p => p.theme.bgPopup};
  border-radius: 5px;
  padding: 30px;
  margin: 20px;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 3px 8px 2px rgba(${p => hexToRgb(p.theme.bgDarker)}, 0.2),
    3px 6px 20px 6px rgba(${p => hexToRgb(p.theme.bgDarker)}, 0.15);

  p {
    margin-top: 0px;
    font-weight: 700;
    font-size: 16px;
  }

  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }
`;

const Hr = styled.hr`
  height: ${p => (p.size === 'small' ? '1' : '2')}px;
  width: ${p => (p.size === 'small' ? '20' : '40')}%;
  background-color: ${p => p.theme.accentLight};
  border: 0;
  margin: 10px auto;
`;

const Popup = props => {
  return (
    <StyledPopup>
      <p>
        Thank you for using this app,
        <br /> just a few tips:
      </p>
      <Hr />
      <ul>
        <li>Longpress on the start/pause button to reset the set list</li>
        <Hr size="small" />
        <li>Tap the title to change the theme</li>
        <Hr size="small" />
        <li>Adjust the delay based on the time you will start the next exercise</li>
      </ul>
    </StyledPopup>
  );
};

export default Popup;
