import React, { useReducer, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import SetList from './SetList';
import Button from './Button';
import Popup from './Popup';
import themes from '../themes';
import Icons from './Icons';
import { TYPES } from '../helpers';
import { useLocalStorage, useOnClickOutside } from '../hooks';

const AppStyled = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, ${p => p.theme.bgDesaturated}, ${p => p.theme.bgDark});
`;

const Title = styled.h1`
  height: 30px;
  margin: 0;
  padding: 10px;
  font-size: 24px;
  user-select: none;
  text-transform: lowercase;
  color: ${p => p.theme.accentLight};
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  min-height: 140px;
`;

const IconsButton = styled.button`
  color: ${p => p.theme.accentLight};
  opacity: 0.9;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 24px;
  outline: transparent;
  padding: 10px;
  display: flex;
  transition: all 0.15s;

  &:active {
    opacity: 1;
  }
`;

const DelayContainers = styled.div`
  display: flex;
`;

const Footer = styled.span`
  color: ${p => p.theme.accentLight};
  opacity: 0.5;
  font-size: 12px;
  text-transform: lowercase;
  padding-bottom: 5px;

  & a {
    color: ${p => p.theme.accentLight};
    font-weight: 800px;
  }
`;

const ThemeOptions = styled.ul`
  position: absolute;
  right: 10px;
  list-style: none;
  color: ${p => p.theme.accentLight};
  text-transform: lowercase;

  li {
    padding-bottom: 8px;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DELAY':
      return {
        ...state,
        delay: action.delay * 1000,
      };
    case 'START_EXERCISE':
      return {
        ...state,
        sets: action.sets,
        timerStart: action.timerStart,
        setsCount: state.setsCount + 1,
        running: TYPES.EXERCISE,
      };
    case 'START_PAUSE':
      return {
        ...state,
        timerStart: action.timerStart,
        running: TYPES.PAUSE,
      };
    case 'STOP':
      return {
        ...state,
        sets: [],
        running: null,
        setsCount: 1,
      };
    case 'UPDATE_SETS':
      return {
        ...state,
        sets: action.sets,
      };
    case 'START_TICKING':
      return {
        ...state,
        ticking: action.ticking,
      };
    case 'TOGGLE_THEME_POPUP':
      return {
        ...state,
        isPopupThemeOpen: !state.isPopupThemeOpen,
      };
    case 'SET_THEME':
      return {
        ...state,
        theme: action.theme,
        isPopupThemeOpen: false,
      };
    default:
      return state;
  }
};

const App = () => {
  const themesList = Object.keys(themes);
  const [state, dispatch] = useReducer(reducer, {
    sets: [],
    setsCount: 1,
    timerStart: 0,
    running: null,
    delay: 5 * 1000,
    ticking: null,
    isPopupThemeOpen: false,
  });

  const { running, delay, ticking, isPopupThemeOpen } = state;

  const [delaySeconds, setDelaySeconds] = useLocalStorage('delay', 5);
  const [theme, setTheme] = useLocalStorage('theme', 'dark');

  const refPopup = useRef();
  const [isPopupOpen, setPopupOpen] = useLocalStorage('popup', true);
  useOnClickOutside(refPopup, () => setPopupOpen(false));

  const tickingRef = useRef();
  tickingRef.current = ticking;
  const stateRef = useRef();
  stateRef.current = state;

  React.useEffect(() => {
    dispatch({ type: 'SET_DELAY', delay: delaySeconds });
  }, []);

  const start = withDelay => {
    let timerStart = Date.now();
    if (withDelay) {
      timerStart += delay;
    }

    if (!running || running === TYPES.PAUSE) {
      const sets = [...state.sets];
      let { setsCount } = state;
      if (sets[sets.length - 1] && withDelay) {
        sets[sets.length - 1].PAUSE += delay;
      }
      sets.push({ count: setsCount, EXERCISE: null, PAUSE: null });
      dispatch({ type: 'START_EXERCISE', sets, timerStart });
      newTicking(TYPES.EXERCISE, 100);
    } else {
      dispatch({ type: 'START_PAUSE', timerStart });
      newTicking(TYPES.PAUSE, 100);
    }
  };

  const newTicking = (type, interval) => {
    clearInterval(ticking);
    dispatch({
      type: 'START_TICKING',
      ticking: setInterval(() => tick(type), interval),
    });
  };

  const tick = type => {
    const sets = [...stateRef.current.sets];
    const time = Date.now() - stateRef.current.timerStart;
    sets[sets.length - 1][type] = time;
    dispatch({ type: 'UPDATE_SETS', sets });
  };

  const stop = () => {
    clearInterval(ticking);
    dispatch({ type: 'STOP' });
  };

  const handleChangeDelay = type => {
    const oldDelay = Number.parseInt(delaySeconds);
    const newDelay = type === 'increase' ? oldDelay + 5 : oldDelay - 5;
    setDelaySeconds(newDelay);
    dispatch({ type: 'SET_DELAY', delay: newDelay });
  };

  const handleChangeTheme = theme => {
    setTheme(theme);
    dispatch({ type: 'TOGGLE_THEME_POPUP', theme });
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <AppStyled>
        <Title onClick={() => dispatch({ type: 'TOGGLE_THEME_POPUP' })}>GymTimer</Title>
        {isPopupThemeOpen && (
          <ThemeOptions>
            {themesList.map(theme => (
              <li key={theme} onClick={() => handleChangeTheme(theme)}>
                {theme}
              </li>
            ))}
          </ThemeOptions>
        )}
        {isPopupOpen && (
          <div ref={refPopup}>
            <Popup />
          </div>
        )}
        <SetList sets={state.sets} />
        <Controls>
          <Button click={() => start(false)} longpress={stop}>
            {running === TYPES.EXERCISE ? 'Pause' : 'Start'}
          </Button>
          <DelayContainers>
            <IconsButton onClick={() => handleChangeDelay('decrease')}>
              <Icons type="minus" stroke={themes[theme].accentLight} />
            </IconsButton>
            <Button click={() => start(true)}>{`Delay ${delaySeconds}`}</Button>
            <IconsButton onClick={() => handleChangeDelay('increase')}>
              <Icons type="plus" stroke={themes[theme].accentLight} />
            </IconsButton>
          </DelayContainers>
        </Controls>
        <Footer>
          Made with love by <a href="https://federicomoretti.dev">Federico Moretti</a>
        </Footer>
      </AppStyled>
    </ThemeProvider>
  );
};

export default App;
