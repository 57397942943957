import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';
import '../animations.css';

import Set from './Set';

const SetList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const setsList = props => (
  <TransitionGroup component={SetList} data-testid="set-list" className="Set-list">
    {props.sets.map(set => (
      <Set key={set.count} details={set} />
    ))}
  </TransitionGroup>
);

setsList.propTypes = {
  sets: PropTypes.array.isRequired,
};

export default React.memo(setsList);
