// https://coolors.co/99d5c9-212742-2d3142-6c969d-645e9d

const themes = {
  dark: {
    bgDesaturated: '#2d3142',
    bgPopup: '#282f4f',
    bgDark: '#212742',
    bgDarker: '#101320',
    accentLight: '#638ca6',
    text: '#95b1c3',
  },
  light: {
    bgDesaturated: '#fff',
    bgPopup: '#fff',
    bgDark: '#fff',
    bgDarker: '#111',
    accentLight: '#638ca6',
    text: '#5d5d5d',
  },
};

export default themes;
