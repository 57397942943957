export function formatTime(time) {
  return (Math.round((time / 1000) * 10 ** 1) / 10 ** 1).toFixed(2);
  // return precisionRound(time / 1000), 2); // .toFixed(2);
}

export const TYPES = {
  EXERCISE: 'EXERCISE',
  PAUSE: 'PAUSE',
};

/* eslint no-bitwise: 0 */
export function hexToRgb(hex) {
  if (typeof hex !== 'string') return;
  const hexNoHash = hex.replace('#', '');
  const bigint = parseInt(hexNoHash, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r},${g},${b}`;
}
